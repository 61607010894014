$montserrat:'Montserrat', sans-serif;
$lato:'Lato', sans-serif;
$red:#cd3434;
$white:#ffffff;
$dark_blue:#16233a;
$grey:#f2f3f8;
$font_grey:#8b90a6;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

@mixin redgradient{
	background: rgba(215,91,91,1);
background: -moz-linear-gradient(-45deg, rgba(215,91,91,1) 0%, rgba(215,91,91,1) 25%, rgba(211,76,76,1) 47%, rgba(207,58,58,1) 66%, rgba(206,54,54,1) 84%, rgba(206,54,54,1) 100%);
background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(215,91,91,1)), color-stop(25%, rgba(215,91,91,1)), color-stop(47%, rgba(211,76,76,1)), color-stop(66%, rgba(207,58,58,1)), color-stop(84%, rgba(206,54,54,1)), color-stop(100%, rgba(206,54,54,1)));
background: -webkit-linear-gradient(-45deg, rgba(215,91,91,1) 0%, rgba(215,91,91,1) 25%, rgba(211,76,76,1) 47%, rgba(207,58,58,1) 66%, rgba(206,54,54,1) 84%, rgba(206,54,54,1) 100%);
background: -o-linear-gradient(-45deg, rgba(215,91,91,1) 0%, rgba(215,91,91,1) 25%, rgba(211,76,76,1) 47%, rgba(207,58,58,1) 66%, rgba(206,54,54,1) 84%, rgba(206,54,54,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(215,91,91,1) 0%, rgba(215,91,91,1) 25%, rgba(211,76,76,1) 47%, rgba(207,58,58,1) 66%, rgba(206,54,54,1) 84%, rgba(206,54,54,1) 100%);
background: linear-gradient(135deg, rgba(215,91,91,1) 0%, rgba(215,91,91,1) 25%, rgba(211,76,76,1) 47%, rgba(207,58,58,1) 66%, rgba(206,54,54,1) 84%, rgba(206,54,54,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d75b5b', endColorstr='#ce3636', GradientType=1 );
}

body{
	font-family: $lato;
	h1,h2,h3,h4,h5,h6,p,a{
		margin: 0;
		font-weight: 400;
	}
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

*{
    &:focus{
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 8px ;
        outline-color: coral ;
        position: relative;
        z-index: 9999;
    }
}

button{
    padding: 0;
    border: none;
    background-color: transparent;
}

.skiplinks{
    z-index: 99999999;
    > a{
        background: rgba(0,0,0,0.9);
        padding: 15px;
        font-size: 15px;
        line-height: 20px;
        color: $white;
        position: absolute;
        left: -100%;
        white-space: nowrap;
        &:focus{
            left: 0;
        }
    }
}

div.page{
	transition: 0.3s all ease;
}

.default_font{
	font-size: 16px;
	line-height: 30px;
	h1{
		font-size: 54px;
	}
	h2{
		font-size: 43px;
	}
	h3{
		font-size: 34px;
	}
	h4{
		font-size: 24px;
	}
	h5{
		font-size: 20px;
		line-height: 32px;
	}
	h6{
		font-size: 18px;
		line-height: 32px;
	}
	ul{

	}
	ol{

	}
	strong{

	}
}

div.text_desc{
    margin-bottom: 30px;
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ul,ol,a{
			padding-bottom: 15px;
			&:last-child{
				padding-bottom: 0;
			}
		}
		h1,h2,h3,h4,h5,h6{
			font-family: $montserrat;
		}
		img{
			max-width: 100%;
			height: auto;
		}
		ul{
			li{
				padding-left: 20px;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('./../img/red_dot.png');
			}
		}
		ol{
			padding-left: 18px;
		}
		table{
			width: 100%;
			text-align: center;
            border: 0;
			tr{
				&:first-child{
					background-color: #e4e5ec;
					td{
						height: 50px;
						border-right: 2px solid $white;
						&:last-child{
							border-right:none;
						}
					}
				}
			}
			tr{
				background-color: $white;
			//	border-bottom: 2px solid #e4e5ec;
				&:last-child{
					border-bottom: none;
				}
				td{
					height: 33px;
				//	border-right: 2px solid #e4e5ec;
					&:last-child{
						border-right: none;
					}
					p{
						padding: 0 10px;
					}
				}
			}
		}
	}
}

header.header{
	position: relative;
	z-index: 2;
	div.logo_box{
		position: relative;
		padding-top: 34px;
	}
	div.header_right{
		> ul{
			text-align: right;
			> li{
				display: inline-block;
				vertical-align: top;
				padding-top: 45px;
				padding-bottom: 45px;
				&:first-child{
					padding-right: 41px;
				}
				&:last-child{
					border-left: 1px solid #d5d9e8;
					padding-left: 41px;
				}
				button.availability_button{
					display: inline-block;
					font-size: 17px;
					text-transform: uppercase;
					font-family: $montserrat;
					font-weight: 600;
					padding: 28px 29px 30px 91px;
					color: $white;
					background-color: $dark_blue;
					border-radius: 50px;
					background-repeat: no-repeat;
					background-position: center left 32px;
					background-image: url('./../img/eye_icon.png');
				}
			}
		}
	}
	div.header_bottom{
		padding: 15px 26px 15px 26px;
		background-color: $red;
		> div{
			display: inline-block;
			vertical-align: middle;
			&.menu_box{
				width: 70%;
				nav.menu{
					> ul{
						> li{
							display: inline-block;
							vertical-align: top;
							&:last-child{
								a{
									padding-right: 0;
									margin-right: 0;
									&:after{
										display: none;
									}
								}
							}
							a{
								font-size: 17px;
								line-height: 17px;
								font-weight: 300;
								font-family: $montserrat;
								color: $white;
								display: inline-block;
								padding-right: 22px;
								padding-left: 21px;
								position: relative;
								&:after{
									content: '';
									display: block;
									height: 100%;
									border-right: 1px solid $dark_blue;
									position: absolute;
									top: 0;
									right: 0;
								}
							}
							&.on{
								a{
									font-weight: 600;
								}
							}
						}
					}
				}
			}
			&.search{
				width: 30%;
				div.holder{
					margin-left: 20%;
					width: 80%;
					position: relative;
					input.input{
						height: 60px;
						width: 100%;
						padding-left: 29px;
						border-radius: 50px;
						border: none;
						background-color: $white;
						color: $font_grey;
						font-size: 17px;
						font-weight: 300;
						font-family: $montserrat;
						//outline: none;
						padding-right: 20%;
					}
					input.submit{
						width: 20%;
						position: absolute;
						top: 0;
						right: 3%;
						height: 100%;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url('./../img/search_icon.png');
						//outline: none;
						background-color: transparent;
						border: none;
                        text-indent: 9999px;
					}
					::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: #6c7390;
					}
					::-moz-placeholder { /* Firefox 19+ */
					  color: #6c7390;
					}
					:-ms-input-placeholder { /* IE 10+ */
					  color: #6c7390;
					}
					:-moz-placeholder { /* Firefox 18- */
					  color: #6c7390;
					}
				}
			}
		}
	}
}

section.nav_way{
	padding: 25px 0;
	div.nav_border{
		padding-bottom: 11px;
		border-bottom: 1px solid #cfcfd1;
		a.return1{
			color: #cd3434;
			font-size: 12px;
			font-weight: 700;
			line-height: 30px;
		}
	}
	div.way{
		> div{
			display: inline-block;
			vertical-align: middle;
			&.default_font{
				font-size: 12px;
			}
			ul{
				> li{
					display: inline-block;
					vertical-align: top;
					margin-right: 15px;
					padding-left: 22px;
					background-repeat: no-repeat;
					background-position: left center;
					background-image: url('./../img/nav_way_arr.png');
					&:first-child{
						padding-left: 0;
						background-image: none;
					}
					&:last-child{
						margin-right: 0;
					}
					&.on{
						a{
							font-weight: 500;
						}
					}
					a{
						font-size: 12px;
						font-family: $montserrat;
						color: $dark_blue;
					}
				}
			}
			&.default_font{
				margin-right: 10px;
				p{
					font-weight: 300;
					font-family: $montserrat;
					color: $dark_blue;
				}
			}
		}
	}
}

main.content{
	&:after{
		content: '';
		display: block;
		position: absolute;
		bottom: 100%;
		height: 45px;
		left: 0;
		width: 100%;
		border-top: 1px solid #d7dae4;
		display: block;
		background-color: $grey;
	}
	position: relative;
	background-color: $grey;
}

aside{
	&.side_left{
		> ul{
			> li{
				margin-bottom: 10px;
				border-top: 3px solid #ff6e6e;
				@include redgradient;
				color: $white;
				font-family: $lato;
				> a, p{
					padding: 18px;
					border-bottom: 1px solid rgba(255,255,255,0.3);
					text-transform: uppercase;
					text-shadow: 0px 2px 2px rgba(24, 24, 24, 0.3);
					font-size: 20px;
   					line-height: 32px;
					font-weight: 600;
				}
				a{
					color: $white;
					display: block;
				}
				> div{
					> ul{
						display: none;
						> li{
							&:first-child{
								padding-top: 18px;
							}
							&:last-child{
								a{
									margin-bottom: 0;
									border-bottom: none;
								}
							}
							padding-left: 20px;
							padding-right: 20px;
							> a{
								border-bottom: 1px solid rgba(255, 255, 255, 0.3);
								font-size: 17px;
								line-height: 24px;
								font-weight: 500;
								text-shadow: 0px 2px 2px rgba(24, 24, 24, 0.3);
								display: block;
								padding-bottom: 18px;
								margin-bottom: 18px;
							}
						}
					}
				}
			}
		}




		div.side_left_box{
			margin-bottom: 10px;
			border-top: 3px solid #ff6e6e;
			@include redgradient;
			color: $white;
			font-family: $lato;
			a{
				color: $white;
			}
			&.toggle_side{
				nav.side_menu{
					display: none;
				}
			}
		}
		header{
			padding: 18px 0;
			border-bottom: 1px solid rgba(255,255,255,0.3);
			div.default_font{
				text-shadow: 0px 2px 2px rgba(24, 24, 24, 0.3);
				padding-left: 18px;
				padding-right: 18px;
				h1,h2,h3,h4,h5,h6,p{
					text-transform: uppercase;
				}
				strong{
					font-weight: 600;
				}
			}
		}
		nav.side_menu{
			padding-left: 20px;
			padding-right: 20px;
			ul{
				padding-top: 18px;
				li{
					&:last-child{
						a{
							border-bottom: none;
							margin-bottom: 0;
						}
					}
					a{
						border-bottom: 1px solid rgba(255,255,255,0.3);
						font-size: 17px;
						line-height: 24px;
						font-weight: 500;
						text-shadow: 0px 2px 2px rgba(24, 24, 24, 0.3);
						display: block;
						padding-bottom: 18px;
						margin-bottom: 18px;
					}
				}
			}
		}
		button.side_more{
			padding: 18px 20px 18px 20px;
			background-color: $white;
			cursor: pointer;
            display: block;
            text-align: left;
            width: 100%;
            span{
                display: block;
            }
			span.default_font{
				color: $dark_blue;
				span{
					background-position: right center;
					background-repeat: no-repeat;
					background-image: url('./../img/red_arr2.png');
					font-weight: 600;
					transition: 0.1s all ease;
					&.active{
						background-image: url('./../img/red_arr3.png');
					}
				}
			}
		}
	}
	&.side_right{
		div.side_right_box{
			margin-bottom: 10px;
			border-top: 3px solid #cd3434;
			background-color: $white;
			font-family: $lato;
			margin-bottom: 10px;
			header{
				padding: 18px 0;
				border-bottom: 1px solid #cfcfd1;
				div.default_font{
					color: $red;
					padding: 0 20px;
                    h2{
                        font-size: 20px;
                        line-height: 32px;
                    }
				}
			}
			div.right_list{
				padding-top: 18px;
				ul{
					li{
						position: relative;
						border-bottom: 1px solid #cfcfd1;
						padding-left: 20px;
						padding-right: 20px;
						padding-bottom: 18px;
						margin-bottom: 18px;
						&:last-child{
							margin-bottom: 0;
							border-bottom: none;
						}
						div.date{
							font-family: $montserrat;
							color: #8b90a6;
							font-size: 14px;
							p{
								font-weight: 500;
							}
						}
						div.text_desc{
							padding-top: 8px;
							div.default_font{
								color: #16233a;
								line-height: 20px;
								h1,h2,h3,h4,h5,h6,p{
									font-weight: 600;
								}
							}
						}
					}
				}
			}
			&.important_sites{
				div.right_list{
					ul{
						li{
							div.text_desc{
								padding-top: 0;
								div.default_font{
									color: #16233a;
									padding-right: 20px;
									background-position: right center;
									background-repeat: no-repeat;
									background-image: url('./../img/red_arr.png');
								}
							}
						}
					}
				}
			}
			&.right_more{
				border-top: 3px solid #cfcfd1;
				div.right_more_box{
					> div{
						display: inline-block;
						vertical-align: middle;
						&.icon{
							width: 16%;
							img{
								max-width: 100%;
								height: auto;
							}
						}
						&.desc{
							width: 84%;
							div.text_desc{
								padding-top: 0;
								div.default_font{
									color: #16233a;
									padding-left: 10px;
									h1,h2,h3,h4,h5,h6,p{
										padding-bottom: 10px;
										&:last-child{
											padding-bottom: 0;
										}
									}
									font-size: 12px;
									line-height: 12px;
									h6{
										font-size: 14px;
										line-height: 16px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

a.all_click{
	@include fill;
}

.more1{
	font-family: $montserrat;
	padding-right: 39px;
	background-position: right center;
	background-repeat: no-repeat;
	background-image: url('./../img/more_arr.png');
	color: #8b90a6;
	font-size: 14px;
	display: inline-block;
	font-weight: 500;
}

section.home{
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 72%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		margin-bottom: 30px;
		div.text_desc{
			z-index: 5;
			position: absolute;
			left: 0;
			bottom: 30px;
			width: 100%;
			div.default_font{
				padding-left: 15px;
				padding-right: 15px;
				color:$white;
				h1,h2,h3,h4,h5,h6{
					color: $white;
				}
                h1{
                    font-size: 34px;
                    line-height: 44px;
                }
				strong{
					font-weight: 600;
				}
			}
		}
		div.cover{
			@include fill;
			background-repeat: repeat-x;
			background-position: left bottom;
			background-image: url('./../img/slider_cover.png');
			background-size: contain;
		}
	}
	div.desc{
		div.text_desc{
			div.default_font{
				color: $dark_blue;
                h2{
                    font-size: 34px;
                }
			}
		}
	}
}

footer.footer{
	position: relative;
	padding-top: 40px;
	background-color: $grey;
    .default_font{
        font-size: 10px !important;
    }
	div.footer_width{
		position: relative;
		z-index: 3;
		margin: 0 6%;
		border-top: 3px solid #ff6e6e;
		background-color: $white;
	}
	div.footer_top{
		padding: 50px 0;
		div.footer_box{
			.fh{
				padding-bottom: 15px;
				margin-bottom: 20px;
				border-bottom: 1px solid $red;
				div.default_font{
                    font-size: 24px;
					color: #16233a;
					font-family: $montserrat;
					strong{
						font-weight: 600;
					}
				}
			}
			div.text_desc{
				div.default_font{
					color: #4e576a;
					strong{
						font-weight: 700;
					}
				}
			}
		}
	}
	div.footer_bottom{
        .default_font{
            font-size: 12px;
        }
		div.footer_width{
			padding-top: 25px;
			padding-bottom: 25px;
			border-top: none;
			background-color: #16233a;
			div{
				color: $white;
			}
			ul{
				li{
					display: inline-block;
				}
			}
			div{
				&.copyright{
					> ul{
						> li{
							vertical-align: middle;
							&.copy{
								//padding-top: 29px;
								//padding-bottom: 29px;
								padding-right: 15px;
								position: relative;
								border-right: 1px solid rgba(255,255,255,0.2);
								&:after{
									content: '';
									display: block;
								}
								div.default_font{
									p{
                                        font-size: 13px;
										font-weight: 300;
										font-family: $montserrat;
									}
								}
							}
							&.to_top{
								padding-left: 15px;
								padding-right: 15px;
								border-right: 1px solid rgba(255,255,255,0.2);
								//padding-top: 14px;
								//padding-bottom: 14px;
								div.default_font{
									font-size: 12px;
									font-family: $montserrat;
									color: #7685a0;
									p{
										cursor: pointer;
									}
								}
							}
							&.site{
								padding-left: 15px;
                                padding-right: 15px;
                                border-right: 1px solid rgba(255,255,255,0.2);
                                line-height: 30px;
								a{
									font-size: 12px;
									font-family: $montserrat;
									color: #7685a0;
								}
							}
						}
					}
				}
				&.devs{
					//padding-top: 30px;
					ul{
						text-align: right;
						li{
							vertical-align: middle;
							div.default_font{
								margin-right: 10px;
								p{
									font-weight: 300;
									font-family: $montserrat;
								}
							}
						}
					}
				}
			}
		}
	}
	div.underfooter{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		background-color: #16233a;
	}
}

header.section_header{
	padding: 19px 0;
	border-top: 3px solid $red;
	background-color: $white;
	margin-bottom: 30px;
	div.default_font{
		text-align: center;
		color: #16233a;
		font-family: $montserrat;
		padding-left: 15px;
		padding-right: 15px;
        h1{
            font-size: 20px;
            line-height: 32px;
        }
		h5{
			line-height: 20px;
		}
		strong{
			font-weight: 500;
		}
	}
}

a.more2{
	font-size: 14px;
	font-weight: 600;
	font-family: $montserrat;
	padding: 21px 28px;
	display: inline-block;
	color: #596272;
	border-radius: 50px;
	border: 2px solid $red;
}

section.classifieds{
	div.classifieds_list{
		> ul{
			> li{
				border-bottom: 1px solid $white;
				padding-bottom: 30px;
				margin-bottom: 30px;
				div.date{
					font-family: $montserrat;
					color: $red;
					font-size: 15px;
					padding-bottom: 10px;
					p{
						font-weight: 500;
					}
				}
				div.text_desc{
					div.default_font{
						padding-bottom: 15px;
						color: #16233a;
						strong{
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}

section.informations{
	div.text_desc{
		div.default_font{
			color: #16233a;
			line-height: 26px;
		}
	}
	div.tables{
		header{
			div.default_font{
				strong{
				padding-left: 16px;
				background-position: left top 7px;
				background-repeat: no-repeat;
				background-image: url('./../img/red_dot.png');
				}
			}
		}
		ul{
			li{
				margin-bottom: 40px;
				table{
					margin-top: 15px;
				}
			}
		}
	}
}

section.contact{
	form{
		margin-top: 30px;
	}
	div.holder{
		margin-bottom: 10px;
		p{
			color: #16233a;
			font-family: $lato;
			font-weight: 500;
			font-size: 17px;
			padding-bottom: 10px;
		}
		textarea.textarea{
			width: 100%;
			height: 200px;
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: 2px solid #e4e5ec;
			//outline: none;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 20px;
			font-size: 17px;
			color: #16233a;
			font-family: $lato;
			resize: none;
		}
		input{
			&.input{
				width: 50%;
				height: 65px;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 2px solid #e4e5ec;
				//outline: none;
				padding-left: 20px;
				padding-right: 20px;
				font-size: 17px;
				color: #16233a;
				font-family: $lato;
			}
			&.submit{
				margin-top: 10px;
				width: 214px;
				font-size: 14px;
				font-weight: 600;
				font-family: $montserrat;
				height: 54px;
				color: #596272;
				border-radius: 50px;
				border: 2px solid $red;
				background-color: transparent;
			}
		}
	}
}

section.download{
	div.download_list{
		ul{
			li{
				margin-bottom: 30px;
				header{
					padding-bottom: 23px;
					strong{
						padding-left: 16px;
						background-position: left top 7px;
						background-repeat: no-repeat;
						background-image: url('./../img/red_dot.png');
					}
				}
				a{
					display: inline-block;
					color: #16233a;
					font-size: 16px;
					font-weight: 700;
					padding-left: 51px;
					padding-top: 12px;
					padding-bottom: 12px;
					background-repeat: no-repeat;
					background-position: left center;
					background-image: url('./../img/pdf_icon.png');
				}
			}
		}
	}
}

.links2{
	a{
		display: inline-block;
		color: #16233a;
		font-size: 16px;
		font-weight: 700;
		padding-left: 51px;
		padding-top: 12px;
		padding-bottom: 12px;
		background-repeat: no-repeat;
		background-position: left center;
		background-image: url('./../img/pdf_icon.png');
	}
}

section.mayor_details{
	div.desc{
		div.image{
			width: 230px;
			height: 218px;
			background-repeat: no-repeat;
			background-position: center;
			margin-bottom: 20px;
			background-size: 100% auto;
		}
		div.contact_data{
			margin-bottom: 23px;
		}
		header{
			margin-bottom: 23px;
			padding: 23px 0;
			border-top: 1px solid $white;
			border-bottom: 1px solid $white;
			div.default_font{
				strong{
					padding-left: 16px;
					background-position: left top 7px;
					background-repeat: no-repeat;
					background-image: url('./../img/red_dot.png');
				}
			}
		}
	}
}

section.mayors{
	div.mayors_list{
		ul{
			text-align: center;
			> li{
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 30%;
				margin-right: 4.6%;
				margin-bottom: 4.6%;
				&:hover{
					> div{
						div.name{
							div.default_font{
								color: $red;
							}
						}
					}
				}
				> div{
					padding: 20px;
					background-color: $white;
					div.image{
						width: 100%;
						height: 0;
						padding-bottom: 100%;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						margin-bottom: 15px;
					}
					div.name{
						div.default_font{
							text-align: center;
							transition: 0.3s all ease;
						}
					}
				}
				&:nth-child(3n){
					margin-right: 0;
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
	div.logo_box img{max-width: 100%; height: auto;}
	footer.footer div.footer_width{margin: 0;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	div.default_font{font-size: 13px; line-height: 27px;}
	div.default_font h1{font-size: 43px; line-height: 53px;}
	div.default_font h2{font-size: 34px; line-height: 43px;}
	div.default_font h3{font-size: 24px; line-height: 34px;}
	div.default_font h4{font-size: 20px; line-height: 30px;}
	div.default_font h5{font-size: 18px; line-height: 28px;}
	div.default_font h6{font-size: 15px; line-height: 30px;}

	aside.side_left nav.side_menu ul li a{font-size: 15px;}
	aside.side_right div.side_right_box div.right_list ul li div.date p{font-size: 12px;}
	aside.side_right div.side_right_box div.right_list ul li a.more1{font-size: 12px;}

	header.header div.header_right > ul > li:first-child{padding-right: 21px;}
	header.header div.header_right > ul > li:last-child{padding-left: 21px;}
	header.header div.header_right > ul > li a.availability_button{font-size: 15px;}

	div.logo_box img{max-width: 100%; height: auto;}
	header.header div.header_bottom > div.menu_box nav.menu > ul > li a{font-size: 15px; padding-right: 14px; padding-left: 14px;}

	footer.footer div.footer_width{margin: 0;}
	footer.footer div.footer_bottom div.footer_width div.copyright > ul > li.to_top div.default_font{font-size: 10px;}
	footer.footer div.footer_bottom div.footer_width div.copyright > ul > li.site a{font-size: 10px;}

	section.mayors div.mayors_list ul > li{margin-right: 3.5%; margin-bottom: 3.5%;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.mayors div.mayors_list ul > li{width: 48%; margin-bottom: 2%; margin-right: 2%!important;}
	section.mayors div.mayors_list ul > li:nth-child(2n){margin-right: 0!important;}

	section.public_orders div.order_table table{font-size: 13px!important; line-height: 23px!important;}
	section.public_orders div.order_table table tr td{padding: 10px!important;}
	section.public_orders div.order_table a{font-size: 10px!important;}
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	header.header div.logo_box{padding-top: 0; margin-top: 10px;}
	div.logo_box img{height: 47px; width: auto;}
	.rwd_button{width: 30px; position: absolute; top: 5px; right: 0;}
	.rwd_button > span{display:block; width: 100%; height: 3px; margin-bottom: 3px; background-color: $red;}
	.rwd_button > span:last-child{margin-bottom: 0;}

	div.header_right img{height: 47px; width: auto;}
	header.header div.header_right > ul > li a.availability_button{padding: 15px 29px 15px 65px; font-size: 13px; background-position: center left 22px; background-size: 30px;}
	header.header div.header_right > ul > li{padding: 15px 0!important;}
	header.header div.header_right > ul > li:first-child{width: 15%; text-align: left;}
	header.header div.header_right > ul > li:last-child{width: 85%; border-left: none;}

	section.home div.image{padding-bottom: 92%;}

	section.contact div.holder input.input{width: 100%;}

	div.classifieds_list{text-align: center;}
	section.classifieds div.classifieds_list > ul > li:last-child{margin-bottom: 0;}
	a.more2{font-size: 13px;}

	div.tables{overflow-x: scroll;}

	div.content_holder{display: flex; flex-flow: column;}
	div.content_holder div.content_left{order: 2; align-items: stretch; margin-bottom: 30px;}
	div.content_holder div.content_middle{order: 1; align-items: stretch; margin-bottom: 30px;}
	div.content_holder div.content_right{order: 3; align-items: stretch;}
	footer.footer div.footer_width{margin: 0;}
	footer.footer div.footer_top{padding: 30px 0 0 0;}
	div.footer_box{margin-bottom: 30px;}
	footer.footer div.footer_bottom div.footer_width div.copyright > ul > li{width: 100%;}
	footer.footer div.footer_bottom div.footer_width div.copyright > ul > li.copy{border-right: none; padding-top: 0; padding-bottom: 0;}
	footer.footer div.footer_bottom div.footer_width div.copyright > ul > li.to_top{padding-left: 0; border-right: none;}
	footer.footer div.footer_bottom div.footer_width div.copyright > ul > li.site{padding-left: 0; border-right: none;}
	footer.footer div.footer_bottom div.footer_width div.devs ul{text-align: left;}
	footer.footer div.footer_bottom div.footer_width div.devs{padding-top: 15px;}
	aside.side_right div.side_right_box.right_more div.right_more_box > div.desc{text-align: right;}

	main.content:after{display: none;}
}

.rwd_button{
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
    }
	&.open{
		right: 15px;
		top: 21px;
		> span{
			background-color: $white;
		}
	}
}

.d_list_header{
    h2{
        font-size: 20px;
        line-height: 32px;
        padding-left: 15px;
        padding-left: 15px;
        background-repeat: no-repeat;
        background-position: left top 12px;
        background-image: url("./../img/red_dot.png");
    }
}

div.rwd_menu{
	overflow-y: scroll;
	z-index: 10;
	width: 255px;
	height: 100%;
	position: fixed;
	top: 0;
	right: -255px;
	@include redgradient;
	transition: 0.3s all ease;
    display: none;
	> div.rwd_padding{
		padding: 15px 65px 0 15px;
		div.header_bottom{
			display: flex;
			flex-flow: column;
			> div{
				&.search{
					order: 1;
					align-items: stretch;
				}
				&.menu_box{
					order: 2;
					align-items: stretch;
				}
			}
		}
		div.holder{
			margin:0 0 20px 0;
			width: 100%;
			position: relative;
			input.input{
				width: 100%;
				height: 30px;
				padding-left: 15px;
				padding-right: 20%;
				//outline: none;
				font-family: $lato;
				font-size: 13px;
				border: none;
				background-color: $white;
				border-radius: 20px;
			}
			input.submit{
				width: 20%;
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url('./../img/search_icon.png');
				//outline: none;
				background-color: transparent;
				border: none;
                text-indent: 999px;
			}
		}
		nav.menu{
			> ul{
				> li{
					a{
						color: $white;
						font-family: $montserrat;
						font-size: 13px;
						line-height: 23px;
						display: inline-block;
						margin-bottom: 8px;
					}
				}
			}
		}
	}
}

section.public_orders{
	div.order_table{
		table{
			background-color: $white;
			border: 1px solid $red;
			font-size: 16px;
			line-height: 26px;
			tr{
				vertical-align: top;
				border-bottom: 1px solid $red;
				&:last-child{
					border-bottom: none;
				}
				td{
					padding: 20px;
					border-right: 1px solid $red;
					&:last-child{
						border-right: none;
					}
				}
			}
		}
		a{
			color: $red;
			font-size: 16px;
			line-height: 26px;
			font-weight: 400;
			&.link_pdf{
				padding-left: 25px;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-right: 5px;
				background-position: left center;
				background-repeat: no-repeat;
				background-image: url('/img/pdf2bip.png');
				background-size: 20px;
			}
			&.link_zip{
				padding-left: 25px;
				padding-top: 5px;
				padding-bottom: 5px;
				background-position: left center;
				background-repeat: no-repeat;
				background-image: url('/img/zip2bip.png');
				background-size: 20px;
			}
		}
	}
}

div.page{
	li.first_opened{
		ul{
			display: block;
		}
	}
}

section.public_orders{
	div.news_list{
		ul{
			li{
				position: relative;
			}
		}
	}
}

section.mayor{
	padding: 30px;
	background-color: $white;
	div.mayor_main{
		position: relative;
		div.image{
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
		div.desc{
			padding-top: 5px;
			header{
				margin-bottom: 10px;
				div.text_desc{
					div.default_font{
						h1,h2,h3,h4,h5,h6,p{
							padding-bottom: 0;
						}
						h1,h2,h3,h4,h5,h6{
							color: $red;
						}
					}
				}
			}
			div.text_desc{
				div.default_font{
					font-family: $lato;
					color: #16233a;
				}
			}
		}
	}
	div.others{
		div.text_desc{
			div.default_font{
				ul{
					li{
						padding: 10px 0 10px 20px;
						border-bottom: 1px solid #cfcfd1;
						a{
							color: #16233a;
							&:hover{
								color: $red;
							}
						}
					}
				}
			}
		}
	}
	div.others{
		padding-top: 50px;
		ul{
			li{
				a{
					font-weight: 700;
				}
			}
		}
	}
}


.declaration{
    padding-top: 25px;
}

.declaration {
    .default_font {
        color: #4b5063;
        h1,h2,h3,h4,h5,h6{
            margin-bottom: 30px;
            color: #102059;
        }
    }
}

.declaration {
    .default_font {
        p{
            margin-bottom: 30px;
        }
    }
}
.declaration {
    .default_font {
        ul{
            list-style: disc;
            padding-left: 18px;
            margin-bottom: 30px;
            color: #007ef5;
        }
    }
}
.declaration {
    .default_font {
        ol{
            margin-bottom: 30px;
        }
    }
}
.declaration{
    .default_font {
        a{
            margin-bottom: 30px;
            color: #007ef5;
        }
    }
}
.declaration {
    .default_font {
        ul {
            li {
                ul, p{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.declaration {
    .default_font {
        ul {
            li {
                p{
                    color: #4b5063;
                }
            }
        }
    }
}

.go_to_declaration{
    text-transform: uppercase;
    color: #7685a0;
    font-size: 10px;
    line-height: 30px;
    padding-left: 15px;
    @media only screen and (max-width: 991px) {
        padding-left: 0;
    }
    &:hover{
        color: #7685a0;
    }
}

.metrics_list{
    > ul{
        > li{
            margin-bottom: 37px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 25px;
            }
            @media only screen and (max-width: 767px) {
                margin-bottom: 15px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.metric_box{
    background-color: $white;
    border-top: 3px solid $red;
    transition: 0.3s all ease;
    &.metric_open{
        box-shadow: 0px 5px 20px 0px rgba(4, 4, 4, 0.1),inset 0px 3px 0px 0px rgba(205, 52, 52, 0.004);
        .metric_top{
            &:after{
                content: url("./../img/metrics_arr2.png");
            }
        }
    }
    .metric_top{
        padding: 16px 50px 16px 20px;
        position: relative;
        width: 100%;
        text-align: left;
        @media only screen and (max-width: 991px) {
            outline: none;
        }
        &:after{
            position: absolute;
            top: 16px;
            right: 20px;
            content: url("./../img/metrics_arr1.png");
        }
        h2{
            font-size: 18px;
            line-height: 28px;
            color: #262626;
            font-weight: 600;
        }
    }
    .metric_bottom{
        padding: 20px;
        color: #262626;
        font-size: 15px;
        line-height: 25px;
        padding-top: 0;
        display: none;
        @media only screen and (max-width: 767px) {
          font-size: 10px;
            line-height: 15px;
        }
        .row{
            padding: 15px 0;
            border-bottom: 1px solid #e6e6ef;
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

[data-whatintent='mouse'] *:focus {
    outline: none;
}
